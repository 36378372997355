//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    info: {
      type: Object,
      default: () => {
        return {
          img: '',
          title: '',
          desc: '',
          btnTxt: ''
        }
      }
    }
  }
}
