//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Paging from '../../components/paging.vue'
import { getDateformat } from '../../util/getDate'
import SmallCard from '@/components/smallCardBox.vue'
export default {
  components: {
    Paging,
    SmallCard
  },
  data() {
    return {
      smallInfo: {
        img: require('@/assets/smallCardImgs/3.png'),
        title: '优惠券',
        desc: '营销必备神器',
        btnTxt: '添加优惠券'
      },
      name: '',
      page: 1,
      pageNum: 10,
      total: 0,
      list: [],
      status: '3',
      type: '4',
      opStatus: [
        {
          value: '3',
          label: '全部'
        },
        {
          value: '1',
          label: '停用'
        },
        {
          value: '2',
          label: '正常'
        }
      ],
      opType: [
        {
          value: '4',
          label: '全部'
        },
        {
          value: '1',
          label: '满减券'
        },
        {
          value: '2',
          label: '立减券'
        },
        {
          value: '3',
          label: '满折券'
        }
      ]
    }
  },
  created() {
    let utils = this.$store.state.utils
    if (utils.is_record) {
      this.page = utils.page
      this.rows = utils.rows
      utils.is_record = !1
    }
    this.getCouponList()
  },
  methods: {
    clearSearch() {
      this.name = ''
      this.status = '3'
      this.type = '4'
      this.getCouponList()
    },
    updateData(val, status) {
      if (status == 0) {
        this.pageNum = val
        this.getCouponList()
      } else {
        this.page = val
        this.getCouponList()
      }
    },
    getDateformat(val) {
      return getDateformat(val)
    },
    recovery(row) {
      this.$confirm('确认是否恢复此优惠券？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$axios
          .post(this.$api.push.editStatus, {
            id: row.id,
            status: 1
          })
          .then((res) => {
            if (res.code == 0) {
              this.getCouponList()
              this.$message({
                message: '恢复成功',
                type: 'success'
              })
            } else {
              this.$message.error(res.msg)
            }
          })
      })
    },
    end(row, index) {
      this.$confirm('确认是否停止发放？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$axios
          .post(this.$api.push.editStatus, {
            id: row.id,
            status: 0
          })
          .then((res) => {
            if (res.code == 0) {
              this.list[index].status = 0
              this.$message({
                message: '停止成功',
                type: 'success'
              })
            } else {
              this.$message.error(res.msg)
            }
          })
      })
    },
    see() {
      this.$router.push('/order/orderList')
    },
    edit(row) {
      this.$router.push({
        path: '/extension/couponEdit',
        query: {
          info: JSON.stringify(row),
          page: this.page,
          rows: this.pageNum
        }
      })
    },
    remove(id, index) {
      this.$confirm('确认删除此优惠券？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$axios
          .post(this.$api.push.couponEel, {
            id: id
          })
          .then((res) => {
            if (res.code == 0) {
              this.$message({
                message: '删除成功',
                type: 'success'
              })
              this.list.splice(index, 1)
            } else {
              this.$message.error(res.msg)
            }
          })
      })
    },
    getCouponList() {
      let data = {
        page: this.page,
        rows: this.pageNum,
        name: this.name
      }
      if (this.status != 3) data.status = this.status
      if (this.type != 4) data.type = this.type
      this.$axios.post(this.$api.push.couponList, data).then((res) => {
        if (res.code == 0) {
          let list = res.result.list
          this.total = res.result.total_number
          this.list = list
        } else {
          this.$message.error(res.msg)
        }
      })
    }
  }
}
